<template>
  <v-navigation-drawer
    right
    v-model="dialog"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
    :permanent="dialog"
  >
    <!-- TOP -->
    <template v-slot:prepend>
      <v-toolbar color="color2 color2Text--text" tile dense class="top">
        <v-toolbar-title>Bulk Move/Copy</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab
          small
          @click.stop="cancel"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <!-- BODY -->
    <v-card flat>
      <!-- STEP 1 -->
      <v-card-text class="pt-3">
        <div class="text-h5 color3--text pb-3">Select The Teams</div>
        <v-data-table
          :headers="[{text: division.teamLabel, value: 'name'}]"
          :items="teams"
          item-key="id"
          show-select dense
          checkbox-color="success"
          hide-default-footer
          :mobile-breakpoint="0"
          :items-per-page="-1"
          v-model="selected"
          :options="{sortBy: ['name']}"
          :disabled="loading.length > 0"
        >
        </v-data-table>
      </v-card-text>
      <!-- STEP 2 -->
      <v-card-text>
        <div class="text-h5 color3--text pb-3">Move or Copy?</div>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="action === 'move' ? 'success white--text' : null"
              @click.stop="action = 'move'"
              class="py-6" block
            >
              Move
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :color="action === 'copy' ? 'success white--text' : null"
              @click.stop="action = 'copy'"
              class="py-6" block
            >
              Copy
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- STEP 3 -->
      <v-card-text>
        <div class="text-h5 color3--text pb-3">{{action | capFirst}} Within This Event or Another?</div>
        <v-row dense>
          <v-col cols="6">
            <v-btn
              :color="window === 0 ? 'success white--text' : null"
              @click.stop="window = 0"
              class="py-6" block
            >
              This
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :color="window === 1 ? 'success white--text' : null"
              @click.stop="window = 1"
              class="py-6" block
            >
              Another
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- STEP 4 -->
      <v-card-text>
        <v-window v-model="window">
          <!-- SAME EVENT -->
          <v-window-item :key="0">
                <template v-if="divisionChoices.length > 0">
                  <div class="text-h5 color3--text pb-3">Select The New Division</div>
                  <v-autocomplete
                    :items="divisionChoices"
                    v-model="newDivision"
                    label="New division"
                    hint="Select the division to move to"
                    persistent-hint
                    item-text="name"
                    return-object
                    clearable
                    color="color3"
                    :disabled="loading.length > 0"
                  ></v-autocomplete>
                </template>
                <v-alert v-else type="error" text>
                  There are no other divisions in this event
                </v-alert>
          </v-window-item>
          <!-- DIFFERENT EVENT -->
          <v-window-item :key="1">
            <div class="text-h5 color3--text pb-3">Select The New Event</div>
            <v-autocomplete
              :items="summaries"
              v-model="selectedSum"
              label="New Event"
              :hint="`Select the event to ${action} to`"
              persistent-hint
              :item-text="s => { return `${s.name} (${s.startDate.format('MM/DD/YY')})` }"
              return-object
              clearable
              color="color3"
              :disabled="loading.length > 0"
              :loading="loading.includes('sum')"
            >
              <template v-slot:append>
                <v-btn
                  color="color3"
                  text icon small
                  :disabled="loading.includes('sum')"
                  :loading="loading.includes('sum')"
                  @click.stop="getList"
                >
                  <v-icon>fas fa-sync-alt</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-expand-transition>
              <v-row dense v-if="!!selectedSum">
                <v-col cols="12">
                  <div class="text-h5 color3--text pb-3">Select The New Division</div>
                  <v-autocomplete
                    :items="newEventDivisionChoices"
                    v-model="newDivision"
                    label="New division"
                    hint="Select the division to move to"
                    persistent-hint
                    item-text="name"
                    return-object
                    clearable
                    color="color3"
                    :disabled="loading.length > 0"
                    :loading="loading.includes('divs')"
                  >
                    <template v-slot:append>
                      <v-btn
                        color="color3"
                        text icon small
                        :disabled="loading.includes('divs')"
                        :loading="loading.includes('divs')"
                        @click.stop="getDivisions"
                      >
                        <v-icon>fas fa-sync-alt</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <!-- BOTTOM -->
    <template v-slot:append>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn color="success" @click.stop="actionClick" :loading="loading.length > 0" :disabled="!valid">{{action}}</v-btn>
        <v-btn color="error" @click.stop="dialog= false" :disabled="loading.length > 0">Cancel</v-btn>
      </v-card-actions>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import Summary from '@/classes/TournamentSummary'
import { firstBy } from 'thenby'
import Tournament from '../../../classes/Tournament'

export default {
  props: ['division', 'waitlist'],
  data () {
    return {
      dialog: false,
      loading: [],
      newDivision: null,
      selected: [],
      tag: 'Moved',
      action: 'move',
      window: 0,
      summaries: [],
      selectedSum: null,
      selectedTournament: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user', 'publicView']),
    teams () {
      return this.waitlist ? this.division.waitlistTeams : this.division.activeTeams
    },
    divisionChoices () {
      const base = this.division && this.action === 'move' ? this.tournament.divisions.filter((d) => {
        return d.id !== this.division.id && !d.canceled
      }) : this.tournament.divisions

      return base.map(d => {
        return {
          name: d.name,
          id: d.id,
          disabled: d.isFull && !this.userIsAdmin
        }
      })
    },
    newEventDivisionChoices () {
      const base = this.selectedTournament ? this.selectedTournament.divisions.filter((d) => {
        return d.id !== this.division.id && !d.canceled
      }) : []

      return base.map(d => {
        return {
          name: d.name,
          id: d.id,
          disabled: d.isFull && !this.userIsAdmin
        }
      })
    },
    userIsAdmin () {
      return this.user && !this.publicView && this.user.isTournamentAdmin(this.tournament)
    },
    valid () {
      return this.selected.length > 0 && !!this.newDivision
    },
    dto () {
      return {
        teamIds: this.selected.map(m => m.id),
        newDivisionId: this.newDivision.id,
        tag: this.division.name
      }
    }
  },
  methods: {
    getList () {
      this.loading.push('sum')
      const query = `active=false&organizationUserName=${this.tournament.organization.username}&withCounts=true&coOp=false`
      this.$VBL.tournament.getAllSummaries(query)
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
          this.summaries.sort(firstBy('unix', -1))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'sum')
        })
    },
    getDivisions () {
      if (this.selectedSum) {
        this.loading.push('divs')
        this.$VBL.tournament.getById(this.selectedSum.id)
          .then(r => {
            this.selectedTournament = new Tournament(this.$VBL, r.data)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'divs')
          })
      }
    },
    actionClick () {
      if (this.action === 'move') {
        this.moveEm()
      } else {
        this.copyEm()
      }
    },
    moveEm () {
      this.loading.push('working')
      this.$VBL.team.bulkMove(this.dto)
        .then(r => { this.dialog = false })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'working') })
    },
    copyEm () {
      this.loading.push('working')
      this.$VBL.team.bulkCopy(this.dto)
        .then(r => { this.dialog = false })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'working') })
    },
    reset () {
      this.tag = 'Moved'
      this.selected = []
      this.newDivision = null
      this.action = 'move'
      this.window = this.divisionChoices.length > 0 ? 0 : 1
      this.summaries = []
      this.selectedSum = null
      this.selectedTournament = null
    },
    open () {
      this.dialog = true
    },
    cancel () {
      this.dialog = false
    }
  },
  watch: {
    dialog: 'reset',
    window: function (v) {
      if (v === 1) this.getList()
    },
    selectedSum: 'getDivisions'
  }
}
</script>
